import React from "react"
import ReCAPTCHA from "react-google-recaptcha"

import LayoutPage from "../components/layout-page"
import SEO from "../components/seo"
import { ValidateEmail } from "../utils/email"
import ImageMeta from "../components/ImageMeta"

class IndexPage extends React.Component {
  state = { signupDisabled: true, email: "", name: "" }

  render() {
    const { signupDisabled, email, name } = this.state
    const emailInvalid = !ValidateEmail.test(email.trim())
    const nameInvalid = name.trim().length === 0

    return (
      <LayoutPage>
        <SEO title={"Plugin"} meta={ImageMeta}/>
        {/*<section className="content_1 bg-light pt-80">
          <div className="container px-xl-0">
            <div className="row justify-content-center">
              <div className="col-xl-7 col-lg-10 text-center" data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
                <div className="f-14 color-heading semibold text-uppercase sp-20">The future of mix:analog</div>
                <h2 className="mt-25">Plug-in version for Digital Audio Workstations</h2>
              </div>
            </div>
          </div>
        </section>*/}
        {/*<section className="content_11 bg-light pt-100 pb-100">
          <div className="container px-xl-0">
            <div className="row" data-aos-duration="600" data-aos="fade-down" data-aos-delay="300">
              <div className="col-lg-1" />
              <div className="col-lg-10 color-main op-7 text-justify">
                <p>
                  Since the very beginning mix:analog is a web application. We believe that at some point most of the tasks that you do with a DAW will be done online or at least
                  partially online and that differences between web pages and applications will continue to blur.
                </p>
                <p>
                  However we simply can't ignore our users when they telling us to change direction. So we have started to work on a 64-bit plug-in version for macOS and Windows.
                  Compatible with Avid Pro Tools, Apple Logic, Steinberg Cubase, Presonus Studio One, REAPER, Bitwig and many more.
                </p>
                <p>
                  Once released, it will work by streaming audio to and from our audio servers in real time. The settings of the gear will be kept both in mix:analog as well as
                  locally in your DAW session. That way you can continue where you left off regardless of where you started.
                </p>
                <p>
                  We are not shipping the Plug-in yet, however we have started development on it. If you want to become an external beta tester of the plug-in version, register
                  with the form below.
                </p>
                <div className="mt-90 mb-25 hr bg-gray h-2 d-none d-md-block" data-aos-duration="600" data-aos="fade-down" />
              </div>
            </div>
            <div className={"row justify-content-center"}>
              <div className={"col-md-4 col-xs-6 text-center"}>
                <a href={"#join-beta"} className={"btn action-1"}>Join Beta Program</a>
              </div>
            </div>
          </div>
        </section>*/}
        <section className="bg-light form_2">
          <div className="container px-xl-0">
            <div className="row text-center text-sm-left" id={"join-beta"}>
              <div className="col-lg-5 col-md-6 col-sm-7">
                <form name="Plug-in Beta List" action="/plugin/thank-you" method="POST" className="pt-105 pb-100" data-netlify="true">
                  <input type="hidden" name="form-name" value="Plug-in Beta List" />
                  <h2 className="mb-45 small" data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
                    Let me know when plug-in is ready
                  </h2>
                  <div data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
                    <div className="mb-10 f-14 semibold text-uppercase sp-20">
                      Name{" "}
                      <sup>
                        <small>(*)</small>
                      </sup>
                    </div>
                    <input
                      type="text"
                      name="name"
                      placeholder="Your name"
                      className="mb-25 w-full input lg border-gray focus-action-1 color-heading placeholder-gray text-center text-sm-left"
                      required
                      value={name}
                      onChange={e => this.setState({ name: e.target.value })}
                    />
                  </div>
                  <div data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
                    <div className="mb-10 f-14 semibold text-uppercase sp-20">
                      Email{" "}
                      <sup>
                        <small>(*)</small>
                      </sup>
                    </div>
                    <input
                      type="email"
                      name="email"
                      placeholder="Your email"
                      className="mb-25 w-full input lg border-gray focus-action-1 color-heading placeholder-gray text-center text-sm-left"
                      required
                      value={email}
                      onChange={e => this.setState({ email: e.target.value })}
                    />
                  </div>
                  <div data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
                    <div className={"mb-25 w-full input lg focus-action-1 color-heading placeholder-gray text-center text-sm-left"}>
                      <ReCAPTCHA onChange={() => this.setState({ signupDisabled: false })} sitekey="6Ld_27AUAAAAADWk3fAMJNr8NfM24uDzBDg7gGBV" />
                    </div>
                  </div>
                  <div className="mt-25 d-flex flex-wrap align-items-center buttons" data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
                    <button type="submit" className="mr-20 mb-20 mb-xl-0 w-210 btn lg action-1" disabled={signupDisabled || emailInvalid || nameInvalid}>
                      Sign Up
                    </button>
                    <div className="color-heading text-adaptive">
                      By signing up you agree to <br />
                      Our Terms and Privacy Policy
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-sm-1" />
              <div className="col-lg-6 col-md-5 col-sm-4 d-none d-sm-block" data-aos-duration="600" data-aos="fade-down" data-aos-delay="900">
                <img srcSet={`${require("../i/form_2_bg@2x.jpg")} 2x`} src={require("../i/form_2_bg.jpg")} className="absolute h-full bg" alt="" />
              </div>
            </div>
          </div>
        </section>
      </LayoutPage>
    )
  }
}

export default IndexPage
